<template>
	<div class="content">
		<div class="news-banner">
			<img src="../assets/tools-banner.png" width="100%" />
		</div>
		<div class="pd30">
			<div class="title mb40 mt12 van-hairline--bottom pb30">
				<span class="ft30 bold">实用工具</span>
				<span class="bl9 ft18"> / Utility Tool</span>
			</div>
			<div class="flex-between van-hairline--bottom pb22 mb22 mt20 flex" v-for="(item,index) in toolList" :key="item.id">
				<div class="ft26 line1" @click="handleToolDetails(item.id)">{{item.title}}</div>
				<van-icon name="arrow"></van-icon>
			</div>
			<div class="pb30">
				<van-pagination v-model="queryParams.pageNum" :items-per-page="queryParams.pageSize"  @change="handleCurrentChange"
								:total-items="total" mode="simple" />
			</div>
		</div>
	</div>
</template>

<script>
	import { toolPageList } from '@/api/index.js'
	export default{
		data(){
			return{
				queryParams: {
					title:'', //
					pageNum: 1,
					pageSize: 10
				},
				total: 0, // 车型总记录数
				toolList: []  // 实用工具列表
			}
		},
		created() {
			this.getToolsPage()
		},
		methods:{
			getToolsPage(){
				toolPageList(this.queryParams).then(res => {
					if(res.code === 200){
						this.toolList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			handleCurrentChange(val){
				this.queryParams.pageNum = val
				this.getToolsPage()
			},
			handleToolDetails(id){
				this.$router.push({
					path: '/tool-details',
					query:{
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
